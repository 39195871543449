// @flow
import * as React from 'react'
import pick from 'lodash/pick'

import { createUserSession, parseResponseErrors } from 'utils/api'
import AuthContext, { USER_DATA_KEYS } from 'lib/authContext'

import AuthHeader from 'components/AuthHeader'
import LoginForm from 'components/LoginForm'

import strings from '../localization'

function LoginPage() {
  const authContext = React.useContext(AuthContext)

  async function handleSubmit(values, actions) {
    actions.setSubmitting(true)

    try {
      const { data } = await createUserSession(values)
      const userData = pick(data, USER_DATA_KEYS)
      authContext.login(data.jwtToken, userData, values.rememberMe)
    } catch (error) {
      actions.setSubmitting(false)
      console.log('Error submitting login request', error)
      if (error.response) {
        console.log('Response details', error.response)

        const submitErrors = parseResponseErrors(error.response)

        if (submitErrors) {
          actions.setErrors(submitErrors)
        }
      }
    }
  }

  return (
    <div className="container">
      <AuthHeader login />

      <LoginForm onSubmit={handleSubmit} />
    </div>
  )
}

LoginPage.title = strings.loginPage.title

export default LoginPage
