// @flow
import * as React from 'react'
import Link from 'next/link'

import InnerLogo from './InnerLogo'

import { colors, media } from '../utils/style'

import strings from '../localization'

type Props = {
  login?: boolean,
  registration?: boolean
}

export default function AuthHeader(props: Props) {
  return (
    <div className="root">
      <Link href="/">
        <a className="logo">
          <InnerLogo />
        </a>
      </Link>

      <nav>
        <Link href="/">
          <a className={props.login ? 'is-active' : ''}>
            {strings.authHeader.login}
          </a>
        </Link>
        <Link href="/registration">
          <a className={props.registration ? 'is-active' : ''}>
            {strings.authHeader.registration}
          </a>
        </Link>
      </nav>

      <style jsx>{`
        .root {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 18px 0;
        }

        a {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          text-decoration: none;
          text-transform: uppercase;
          color: ${colors.inputText};
        }

        a.is-active {
          color: #14145a;
        }

        .logo {
          position: relative;
          text-decoration: none;

          color: ${colors.dashboardDarkBlue};
        }

        .logo :global(svg) {
          width: 85px;
          height: auto;
        }

        nav {
          display: none;
        }

        @media (min-width: ${media.desktop}px) {
          .root {
            justify-content: space-between;
            padding: 13px 0 48px;
          }

          nav {
            display: block;
          }

          nav a {
            padding-top: 21px;
            padding-bottom: 21px;
          }

          nav a:not(:last-child) {
            padding-right: 33px;
          }

          .logo :global(svg) {
            width: 135px;
            height: 38px;
          }
        }
      `}</style>
    </div>
  )
}
