// @flow
import * as React from 'react'
import { Formik, Field } from 'formik'
import Link from 'next/link'

import { Button, TextInputField, CheckboxInput } from './form'

import { colors, fonts, media } from '../utils/style'
import { loginFormValidation } from '../utils/validation'

import strings from '../localization'

type Props = {
  onSubmit: Function
}

export default function LoginForm({ onSubmit }: Props) {
  return (
    <React.Fragment>
      <Formik validationSchema={loginFormValidation} onSubmit={onSubmit}>
        {formProps => (
          <form onSubmit={formProps.handleSubmit} noValidate>
            <h1>{strings.loginPage.title}</h1>

            <div className="field">
              <Field
                name="email"
                type="email"
                label={strings.loginPage.form.email}
                component={TextInputField}
              />
            </div>
            <div className="field">
              <Field
                name="password"
                type="password"
                label={strings.loginPage.form.password}
                component={TextInputField}
                autoComplete="current-password"
              />
            </div>

            <div className="actions">
              <div className="remember-me">
                <CheckboxInput name="rememberMe">
                  {strings.loginPage.form.rememberMe}
                </CheckboxInput>
              </div>
              <div className="forgot-password">
                <Link href="/forgot-password">
                  <a>{strings.authSecondaryActions.forgotPassword}</a>
                </Link>
              </div>
            </div>

            <div className="actions">
              <div className="secondary-action">
                <div className="label">
                  {strings.authSecondaryActions.haveNoAccount}
                </div>
                <Link href="/registration">
                  <a className="regisrtation-link">
                    {strings.authSecondaryActions.registration}
                  </a>
                </Link>
              </div>
              <div className="primary-action">
                <Button
                  disabled={formProps.isSubmitting}
                  onClick={() => formProps.handleSubmit()}
                  type="submit"
                >
                  {formProps.isSubmitting
                    ? strings.loginPage.form.submitting
                    : strings.loginPage.form.submit}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <style jsx>{`
        form {
          width: 100%;
          max-width: 377px;
          margin: 0 auto;
        }

        h1 {
          margin: 0 0 34px 0;

          font-family: ${fonts.IBMPlex};
          font-size: 24px;
          font-weight: bold;
          line-height: 28px;
          text-align: left;

          color: #14145a;
        }

        .field {
          margin-bottom: 10px;
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 17px 0 19px;
        }

        .actions > * {
          flex: 1;
        }

        .actions a {
          font-family: ${fonts.IBMPlex};
          font-size: 12px;

          color: ${colors.grayText};
        }

        .actions a.regisrtation-link {
          font-size: 14px;
        }

        .forgot-password {
          position: relative;
          top: -2px;

          line-height: 21px;
          text-align: right;
        }

        .secondary-action .label {
          margin-bottom: -3px;
          font-family: ${fonts.IBMPlex};
          font-size: 12px;
          line-height: 1;

          color: ${colors.inputText};
        }

        @media (min-width: ${media.desktop}px) {
          form {
            max-width: 483px;
            padding: 38px 53px 15px;
            border-radius: 6px;

            background: ${colors.white};
            box-shadow: 0px 10px 20px rgba(144, 144, 144, 0.1);
          }

          h1 {
            margin: 0 0 37px 0;

            font-size: 50px;
            line-height: 58px;
            text-align: center;
          }

          .field {
            margin-bottom: 20px;
          }

          .actions {
            margin: 0 0 26px;
          }

          .actions a {
            font-size: 14px;
          }

          .actions a.regisrtation-link {
            font-size: 16px;
          }

          .forgot-password {
            position: static;
          }
        }
      `}</style>
    </React.Fragment>
  )
}
